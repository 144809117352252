import { axios, get, post, patch, Delete } from "./request";

export const ajax = {
  //发送邮箱验证码
  sendMail: (params, headers) =>
    get("/api/v1.0/common/sendMail", params, headers),
  // 注册
  registerByVerificationCode: (params, headers) =>
    post("/api/v1.0/user/registerByVerificationCode", params, headers),
  // 邮箱登录
  loginByVerificationCode: (params, headers) =>
    post("/api/v1.0/user/loginByVerificationCode ", params, headers),
  // 获取用户信息
  getUserInfo: (params, headers) =>
    post("/api/v1.0/user/getUserInfo", params, headers),
  // 实名认证
  realNameAli: (params, headers) =>
    get("/api/v1.0/common/realNameAli", params, headers),
  // 发送手机验证码
  sendSms: (params, headers) =>
    get("/api/v1.0/common/sendSms", params, headers),
  // 获取用户实名
  getRealNameInfo: (params, headers) =>
    post("/api/v1.0/user/getRealNameInfo", params, headers),
  // 换绑邮箱
  changeMailBinding: (params, headers) =>
    get("/api/v1.0/user/changeMailBinding", params, headers),
  // 新增地址
  insert: (params, headers) =>
    post("/api/v1.0/userAddress/insert", params, headers),
  // 新增地址列表
  getAddressListByUserId: (params, headers) =>
    get("/api/v1.0/userAddress/getAddressListByUserId", params, headers),
  // 设置默认地址
  setIsCheck: (params, headers) =>
    get("/api/v1.0/userAddress/setIsCheck", params, headers),
  // 编辑地址
  updateAddress: (params, headers) =>
    get("/api/v1.0/userAddress/updateAddress", params, headers),
  // 退出登录
  logOff: (params, headers) => post("/api/v1.0/user/logOff", params, headers),
  // 兑换列表
  exchangeList: (params, headers) =>
    get("/api/v1.0/exchange/exchangeList", params, headers),
  // 兑换
  doExchange: (params, headers) =>
    get("/api/v1.0/exchange/doExchange", params, headers),
  // 绑定上级
  bindingSuperior: (params, headers) =>
    get("/api/v1.0/user/bindingSuperior", params, headers),
  // 修改信息
  updateInfo: (params, headers) =>
    post("/api/v1.0/user/updateInfo", params, headers),
  // 查看下级
  mySubordinates: (params, headers) =>
    get("/api/v1.0/user/mySubordinates", params, headers),
  // 查看上级
  mySuperior: (params, headers) =>
    get("/api/v1.0/user/mySuperior", params, headers),
  // 查询积分，金币
  userNum: (params, headers) => get("/api/v1.0/user/num", params, headers),
  // 积分任务
  inviteTaskList: (params, headers) =>
    get("/api/v1.0/inviteTask/list", params, headers),
  // 获取用户数量
  userInviteTask: (params, headers) =>
    get("/api/v1.0/user/inviteTask", params, headers),
  // 完成任务
  inviteTaskGet: (params, headers) =>
    get("/api/v1.0/inviteTask/get", params, headers),
  // 查询首发
  activityProductList: (params, headers) =>
    get("/api/v1.0/activityProduct/list", params, headers),
  // banner
  systemBanner: (params, headers) =>
    post("/api/v1.0/systemBanner", params, headers),
  // 公告查询最上级分类
  noticeType: (params, headers) =>
    get("/api/v1.0/systemNotice/noticeType", params, headers),
  // 公告查询
  systemNotice: (params, headers) =>
    post("/api/v1.0/systemNotice", params, headers),
  // 公告查询
  systemNews: (params, headers) =>
    get(`/api/v1.0/systemNotice/${params}`,),
  // 获取验证码
  getImgBase64: (params, headers) =>
    get(`/api/v1.0/user/getImgBase64/${params.key}`,),
  // 文件上传
  fileUpload: (params, headers) =>
    post(`/api/v1.0/common/fileUpload`, params, headers),
  // 问题反馈
  problemFeedback: (params, headers) =>
    post(`/api/v1.0/user/problemFeedback`, params, headers),
  // 查询战队列表
  queryGuildById: (params, headers) =>
    get(`/api/v1.0/guild/queryGuildById`, params, headers),
  // 创建战队
  createGuild: (params, headers) =>
    get(`/api/v1.0/guild/createGuild`, params, headers),
  // 成员列表
  memberList: (params, headers) =>
    get(`/api/v1.0/guild/memberList`, params, headers),
  // 申请列表
  applyList: (params, headers) =>
    get(`/api/v1.0/guild/applyList`, params, headers),
  // 解散战队
  disband: (params, headers) =>
    get(`/api/v1.0/guild/disband`, params, headers),
  // 同意or拒绝
  agreeOrRefuse: (params, headers) =>
    post(`/api/v1.0/guild/agreeOrRefuse`, params, headers),
  // 申请加入
  apply: (params, headers) =>
    get(`/api/v1.0/guild/apply`, params, headers),
  // 退出战队
  guildExit: (params, headers) =>
    get(`/api/v1.0/guild/exit`, params, headers),

  // 获取抽奖任务
  listTask: (params, headers) =>
    get(`/api/v1.0/drawTask/listTask`, params, headers),
  // 任务参与人数
  numberOfParticipants: (params, headers) =>
    get(`/api/v1.0/drawTask/numberOfParticipants`, params, headers),
  // 任务参与人数
  submitTask: (params, headers) =>
    post(`/api/v1.0/drawTask/submitTask`, params, headers),
  // 积分排行榜
  rankList: (params, headers) =>
    get(`/api/v1.0/drawTask/rankList`, params, headers),
  // 获取抽奖次数
  getOwnerNumByType: (params, headers) =>
    get(`/api/v1.0/integration/getOwnerNumByType`, params, headers),
  // 转盘所有奖项
  allPrize: (params, headers) =>
    get(`/api/v1.0/drawTask/allPrize`, params, headers),
  // 抽奖
  doDraw: (params, headers) =>
    get(`/api/v1.0/drawTask/doDraw`, params, headers),
  // 抽奖记录
  drawLog: (params, headers) =>
    get(`/api/v1.0/drawTask/drawLog`, params, headers),
  // 是否签到
  isSign: (params, headers) =>
    get(`/api/v1.0/drawTask/isSign`, params, headers),
  // 签到
  signIn: (params, headers) =>
    get(`/api/v1.0/drawTask/signIn`, params, headers),
  // 修改支付密码
  setPayPassword: (params, headers) =>
    get(`/api/v1.0/user/setPayPassword`, params, headers),

  // 钱包开户
  hfAccountOpening: (params, headers) =>
    get(`/api/v1.0/hfPay/hfAccountOpening`, params, headers),

  // 汇付钱包开户缴费2元
  hfAccountOpenPay: (params, headers) =>
    get(`/api/v1.0/hfPay/hfAccountOpenPay`, params, headers),

  // 获取二级分类
  getLevelTwoTypeList: (params, headers) =>
    get(`/api/v1.0/product/getLevelTwoTypeList`, params, headers),
  // 我的背包
  myBag: (params, headers) =>
    get(`/api/v1.0/product/myBag`, params, headers),
  // 根据分类id获取分类详情
  getTypeById: (params, headers) =>
    get(`/api/v1.0/product/getTypeById`, params, headers),
  // 买入售出
  buyOrSell: (params, headers) =>
    get(`/api/v1.0/wantBuy/buyOrSell`, params, headers),

  // 兑换日志
  exchangeLog: (params, headers) =>
    get(`/api/v1.0/exchange/exchangeLog`, params, headers),
  // 我的订单
  myOrder: (params, headers) =>
    get(`/api/v1.0/wantBuy/myOrder`, params, headers),
  // 取消
  cancelOrder: (params, headers) =>
    get(`/api/v1.0/wantBuy/cancelOrder`, params, headers),
  // 获取商品分类
  getTypeList: (params, headers) =>
    get(`/api/v1.0/product/getTypeList`, params, headers),
  // 求购市场
  market: (params, headers) =>
    get(`/api/v1.0/wantBuy/market`, params, headers),
  // 二级购买
  buy: (params, headers) =>
    get(`/api/v1.0/wantBuy/buy`, params, headers),
  // 是否设置支付密码
  checkIsSetPayPassword: (params, headers) =>
    post(`/api/v1.0/user/checkIsSetPayPassword`, params, headers),

  // 卖给求购人
  wantBuySell: (params, headers) =>
    get(`/api/v1.0/wantBuy/sell`, params, headers),
  // 查询手续费
  getRate: (params, headers) =>
    get(`/api/v1.0/wantBuy/getRate`, params, headers),
  // 查询积分手续费
  getRateNotMoney: (params, headers) =>
    get(`/api/v1.0/wantBuy/getRateNotMoney`, params, headers),
  // 商品剩余数量
  remainingQuantity: (params, headers) =>
    get(`/api/v1.0/wantBuy/remainingQuantity`, params, headers),

  // 发布求购or寄售
  release: (params, headers) =>
    post(`/api/v1.0/wantBuy/release`, params, headers),
  // 首发购买
  rushToPurchase: (params, headers) =>
    get(`/api/v1.0/productOrder/rushToPurchase`, params, headers),
  // 邀请排行榜
  inviteRank: (params, headers) =>
    get(`/api/v1.0/user/inviteRank`, params, headers),
  // 活动可购买次数
  getBuyNum: (params, headers) =>
    get(`/api/v1.0/integration/getBuyNum`, params, headers),
  // 牛仔扣兑换门票
  cowboyBuckleToTicket: (params, headers) =>
    get(`/api/v1.0/exchange/cowboyBuckleToTicket`, params, headers),
  // 我的金钱交易订单
  listOrder: (params, headers) =>
    get(`/api/v1.0/productOrder/listOrder`, params, headers),
  // 获取版本信息
  getLast: (params, headers) =>
    get(`/api/v1.0/app/appUpdate/getLast`, params, headers),

  listAllPledge: (params, headers) =>
    get(`/api/v1.0/pledge/listAllPledge`, params, headers), // 质押任务列表
  doPledge: (params, headers) =>
    get(`/api/v1.0/pledge/doPledge`, params, headers),// 进行质押
  everyDayIncome: (params, headers) =>
    get(`/api/v1.0/pledge/everyDayIncome`, params, headers),// 每日释放的芯片积分、晶体积分数量 初级1 中级2 高级3

  crystalIntegralTodayAndTotal: (params, headers) =>
    get(`/api/v1.0/pledge/crystalIntegralTodayAndTotal`, params, headers),// 晶体积分今日获得、累计获得
  mySubordinates: (params, headers) =>
    get(`/api/v1.0/pledge/mySubordinates`, params, headers),// 查看下级或下下级为我带来的晶体积分收益
  mySubordinatesEnergyConversionMachineNum: (params, headers) =>
    get(`/api/v1.0/pledge/mySubordinatesEnergyConversionMachineNum`, params, headers),// 下级开通能量转换机的数量 初级1 中级2 高级3
  crystalNum: (params, headers) =>
    get(`/api/v1.0/user/crystalNum`, params, headers),// 查询用户晶体or晶体积分
  openNoviceGiftPack: (params, headers) =>
    get(`/api/v1.0/product/openNoviceGiftPack`, params, headers),// 开新手礼包
  payCancelOrder: (params, headers) =>
    get(`/api/v1.0/productOrder/cancelOrder`, params, headers),// 取消订单
  openEquipmentBlindBox: (params, headers) =>
    get(`/api/v1.0/product/openEquipmentBlindBox`, params, headers),// 开装备盲盒
  bindUser: (params, headers) =>
    get(`/api/v1.0/user/bindUser`, params, headers),// 绑定账号
  getHbNum: (params, headers) =>
    get(`/api/v1.0/user/getHbNum`, params, headers),// 获取虎贝数量
  hbToPaoPaoKa: (params, headers) =>
    get(`/api/v1.0/user/hbToPaoPaoKa`, params, headers),// 虎贝兑换泡泡卡

};
